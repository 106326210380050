
import Vue from "vue";
import ITransactioner from "@/lib/interfaces/transactioner";
import TransactionersList from "@/components/subviews/transactioners/transactioners-list.vue";
import TransactionersMaintenance from "@/components/subviews/transactioners/transactioners-maintenance.vue";

export default Vue.extend({
  name: "Transactioners",

  components: { TransactionersMaintenance, TransactionersList },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      transactioner: {} as ITransactioner,
    };
  },
});
