
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import ITransactioner from "@/lib/interfaces/transactioner";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "TransactionersList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    transactioner: {
      type: Object as PropType<ITransactioner>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      transactioners: new Array<ITransactioner>(),

      headers: [
        { text: "Descrição", value: "descricao" },
        { text: "Ordem", value: "torder" },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getTransactioners().then();
    },

    options: {
      deep: true,
      handler() {
        this.getTransactioners().then();
      },
    },
  },

  computed: {
    cTransactioners(): Array<Record<string, unknown>> {
      return this.transactioners.map((transactioner) => ({
        ...transactioner,

        cDesativado: transactioner.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    openTransactioner(id: string) {
      const transactioner = this.transactioners.find(
        (transactioner) => transactioner.id === id
      );
      this.$emit("update:transactioner", { ...transactioner });
    },

    async getTransactioners() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("transacionadores.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.transactioners =
          rsp.getTable("transacionadores")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
